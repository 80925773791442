import React, { useEffect } from 'react';
import './Hero.css';
import { split } from "../../Animations/Text";

function Hero() {
  useEffect(() => {
    split(); // Run the split animation on load
  }, []);

  return (
    <div className='hero'>
      <p data-animation='paragraph'>BSB MANAGEMENT</p>
      <h2 data-animation='header'>TOP MUSIC, FILM & BUSINESS MANAGEMENT AGENCY</h2>
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-film-64_pxg4z7.png' 
        className='reel' 
        data-animation='image' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-music-record-96_vairye.png' 
        className='record' 
        data-animation='image' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631417/icons8-guitar-96_mdfl54.png' 
        className='mic' 
        data-animation='image' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-microphone-96_jbali8.png' 
        className='guitars'
        data-animation='image' 
        alt='niche' 
      />
    </div>
  );
}

export default Hero;
